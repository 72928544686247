'use client';

import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Button } from '@/components/ui/button';
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import Image from 'next/image';
import logo from '../quoting/datacentrix-logo-full.svg';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [status, setStatus] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // Breadcrumb to track page load
  useEffect(() => {
    Sentry.addBreadcrumb({
      category: 'LoginPage',
      message: 'Login page loaded',
      level: 'info'
    });
  }, [email]);
  const handleLogin = async (e: {
    preventDefault: () => void;
  }) => {
    e.preventDefault();
    Sentry.addBreadcrumb({
      category: 'LoginPage',
      message: 'User initiated login',
      level: 'info'
    }); // Add breadcrumb for login attempt

    try {
      const response = await fetch('https://daas.dev.datacentrix.cloud/api/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          password: password
        })
      });
      if (response.ok) {
        const responseOpt = await fetch('https://daas.dev.datacentrix.cloud/api/send-otp/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email
          })
        });
        if (responseOpt.ok) {
          setStatus('true');
          sessionStorage.setItem('email', email);
          sessionStorage.setItem('orgstatus', 'true');
          try {
            const response = await fetch(`https://daas.dev.datacentrix.cloud/api/get_user_by_email/?email=${encodeURIComponent(email)}`);
            if (response.ok) {
              const data = await response.json();
              const userName = data.firstname + (data.lastname ? ' ' + data.lastname : '');
              sessionStorage.setItem('userName', userName);
              sessionStorage.setItem('role', data.type);
              const organisation = data.organisation ? data.organisation : '';
              sessionStorage.setItem('organisation', organisation);
              document.cookie = `role=${data.type}; path=/;`;
            } else {
              // Log a message in Sentry for OTP failure
              Sentry.captureMessage('OTP request failed', 'error');
              throw new Error('Failed to fetch user details.');
            }
          } catch (error) {
            Sentry.captureException(error); // Capture error for fetching user details
            console.error('Fetch error:', error);
          }
          setOtpSent(true);
          setStep(2);
        } else {
          throw new Error('Failed to send OTP.');
        }
      } else {
        alert('Wrong email or password');
        Sentry.captureMessage('Invalid login attempt', 'warning');
      }
    } catch (error) {
      Sentry.captureException(error); // Capture login errors
      console.error('Login error:', error);
    }
  };
  const handleVerifyOtp = async (e: {
    preventDefault: () => void;
  }) => {
    e.preventDefault();
    Sentry.addBreadcrumb({
      category: 'OTP',
      message: 'User verifying OTP',
      level: 'info'
    }); // Add breadcrumb for OTP verification

    try {
      const response = await fetch('https://daas.dev.datacentrix.cloud/api/verify-otp/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          otp: otp
        })
      });
      if (response.ok) {
        const role = sessionStorage.getItem('role');
        if (role !== 'customer') {
          window.location.href = '/admin';
        } else {
          window.location.href = '/dashboard';
        }
      } else {
        throw new Error('Invalid OTP.');
      }
    } catch (error) {
      Sentry.captureException(error); // Capture OTP verification errors
      alert('Invalid OTP. Please try again.');
    }
  };
  return <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8" data-sentry-component="LoginPage" data-sentry-source-file="page.tsx">
      <div className="max-w-md w-full space-y-8">
        {step === 1 && <div>
            <div className="text-center">
              {/* Logo */}
              <div className="flex justify-center mb-4">
                <Image src={logo} alt="Logo" width={250} height={250} />
              </div>
              <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
                Sign in
              </h2>
            </div>
            <form className="mt-8 space-y-6" onSubmit={handleLogin}>
              <div className="rounded-md shadow-sm space-y-4">
                <div>
                  <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <input id="email-address" name="email" type="email" autoComplete="email" onChange={e => setEmail(e.target.value)} required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Email address" />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div className="relative w-full">
                    <input id="password" name="password" type={showPassword ? 'text' : 'password'} autoComplete="current-password" required onChange={e => setPassword(e.target.value)} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Password" />
                    <button type="button" onClick={() => setShowPassword(!showPassword)} className="absolute inset-y-0 right-0 pr-3 flex items-center">
                      {showPassword ? <AiOutlineEyeInvisible className="h-5 w-5 text-gray-500" aria-hidden="true" /> : <AiOutlineEye className="h-5 w-5 text-gray-500" aria-hidden="true" />}
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a href="/forgotpassword" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot password?
                  </a>
                </div>
              </div>

              <div>
                <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
                  Sign in
                </button>
              </div>

              <div className="relative flex items-center justify-center mt-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative text-sm text-gray-500 bg-gray-50 px-2"></div>
              </div>


              <div className="text-center text-sm text-gray-600 mt-6">
                Don't have an account?{' '}
                <a href="/register" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Register Now
                </a>
              </div>
            </form>
          </div>}
        {step === 2 && otpSent && <div>
            <div className="text-center">
              <p className="mt-2 text-center text-lg text-gray-600 font-bold">
                An OTP has been sent to your email address ({email})
              </p>
            </div>
            <form className="mt-8 space-y-6">
              <div className="rounded-md shadow-sm space-y-4">
                <div>
                  <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
                    OTP
                  </label>
                  <input id="otp" name="otp" type="text" required onChange={e => setOtp(e.target.value)} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter OTP" />
                </div>
              </div>

              <div className="flex justify-between">
                <button type="button" className="text-indigo-600 hover:text-indigo-500 font-medium"
            // onClick={handleResendOtp}
            >
                  Resend OTP
                </button>
                <button type="button" className="text-indigo-600 hover:text-indigo-500 font-medium" onClick={() => setStep(1)}>
                  Change Login Details
                </button>
              </div>

              <div>
                <button type="submit" onClick={handleVerifyOtp} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
                  Verify OTP
                </button>
              </div>
            </form>
          </div>}
      </div>
    </div>;
}